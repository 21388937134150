import gql from 'graphql-tag'

export const SEND_ANSWER = gql`
  mutation ($submission: ID!, $question: ID!, $answer: String!, $completionTime:Int) {
    sendAnswer (
      submission: $submission
      question: $question
      answer: $answer
      completionTime:$completionTime
    ) {
      startAt
      endAt
      question
      answer
    }
  }
`

export const GET_SUBMISSION = gql`
  query ($submission: SubmissionInput!) {
    submission (submission: $submission) {
      _id
      candidate {
        _id
        name
        email
      }
      done
      link
      submittedAt
      score
      answers {
        endAt
        question
      }
      quiz {
        _id
        name
        questionsLength
        type
        questions {
          _id
          body
          snippet {
            mode
            value
          }
          answers
          duration
        }
      }
    }
  }
`

export const GET_QUIZ = gql`
  query ($_id: ID!) {
    quiz (_id: $_id) {
      _id
      name
      type
      questionsLength
      questions {
        _id
        body
        snippet {
          mode
          value
        }
        answers
        answer
        score
        duration
      }
    }
  }
`

export const GET_SECRET_SUBMISSION = gql`
  query ($token: String!) {
    sharedSubmission (token: $token) {
      _id
      candidate {
        _id
        name
      }
      quizName
      score
      subScores {
        id
        topicName
        score
      }
      quiz {
        avgScore
        totalSubmission
        questions {
          correctAnswers
          question {
            _id
            body
            answers
            answer
            snippet {
              mode
              value
            }
          }
        }
      }
      answers {
        startAt
        endAt
        question
        answer
        completionTime
      }
    }
  }
`
