import React, { Component } from "react"
import { withApollo } from "react-apollo"

import { GET_SUBMISSION, SEND_ANSWER } from "../actions"

import Wrapper from "../Components/Wrapper"
import CountDown from "../Components/CountDown"
import Header from "../Components/Header"
import TimeOut from "../Components/TimeOut"
import Question from "../Components/Question"
import Congratz from "../Components/Congratz"

const last = (arr) => arr[arr.length - 1]

class PrivateQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submission: { ...props.submission },
      quizPaused: false,
      completionTime: 0,
      requestSent: false
    }
  }

  toggleQuizStatus = async () => {
    const { quizPaused } = this.state
    const newState = {
      quizPaused: !quizPaused,
    }
    if (quizPaused) {
      try {
        const {
          submission: { candidate, quiz },
        } = this.state
        const submissionInput = { email: candidate.email, quiz: quiz._id }
        const {
          data: { submission },
        } = await this.props.client.query({
          query: GET_SUBMISSION,
          variables: { submission: submissionInput },
          fetchPolicy: "network-only",
        })
        newState.submission = submission
      } catch (error) {
        console.log(error)
      }
    }
    this.setState({
      ...newState,
    })
  }
  updateCompletionTime = (completionTime) => {
    this.setState({ completionTime })
  }
  handleSubmitAnswer = async (answer) => {
    this.setState({ requestSent: true })

    const {
      submission: { candidate, quiz, _id },
      completionTime,
    } = this.state
    const variables = {
      submission: _id,
      question: last(quiz.questions)._id,
      answer,
      completionTime,
    }

    try {
      const answerSent = await this.props.client.mutate({ mutation: SEND_ANSWER, variables })
      const submissionInput = { email: candidate.email, quiz: quiz._id }
      const {
        data: { submission },
      } = await this.props.client.query({
        query: GET_SUBMISSION,
        variables: { submission: submissionInput },
        fetchPolicy: "network-only",
      })
      this.setState({ submission, requestSent: false })
      return !!answerSent && !!submission
    } catch (error) {
      window.heap.addEventProperties({
        Question: this.props.question._id,
        Answer: this.state.selectedAnswer,
      })
      console.log(error)
      return false
    }
  }
  render() {
    const { submission, quizPaused, requestSent } = this.state
    const {
      quiz: { name, questions },
      candidate,
      done,
      answers,
    } = submission
    const question = last(questions)
    const answer = answers.filter((x) => x.question === question._id)[0]
    let timeLeft
    if (answer) {
      const timeLeftMs = new Date(answer.endAt) - new Date()
      timeLeft = parseInt(timeLeftMs / 1000, 10)
    } else {
      timeLeft = question.duration
    }
    if (done) {
      window.heap.track("submitQuiz", {
        id: this.state.submission._id,
        quizId: this.state.submission.quiz._id,
        score: submission.quiz.type === "public" ? submission.score : null,
      })

      return (
        <div style={{ display: "flex" }}>
          <Wrapper style={{ display: "flex" }}>
            <Congratz
              submittedAt={this.state.submission.submittedAt}
              quizType={submission.quiz.type}
              score={
                submission.quiz.type === "public" ? submission.score : null
              }
              link={submission.link}
              submissionId={submission._id}
              quizId={submission.quiz._id}
              submissionEmail={submission.email}
            />
          </Wrapper>
        </div>
      )
    }
    return (
      <Wrapper>
        <Header name={candidate.name} />
        {quizPaused ? (
          <TimeOut onResumeQuiz={this.toggleQuizStatus} />
        ) : (
            <>
              <CountDown
                duration={question.duration}
                answerSent={requestSent}
                timeLeft={timeLeft}
                updateCompletionTime={this.updateCompletionTime}
                onQuestionTimeOut={this.toggleQuizStatus}
              />
              <Question
                quizName={name}
                questionNbr={questions.length}
                totalQuestions={submission.quiz.questionsLength}
                question={question}
                onSubmit={this.handleSubmitAnswer}
              />
            </>
          )}
        {/* <Question
          quizName={name}
          questionNbr={questions.length}
          question={question}
          onSubmit={this.handleSubmitAnswer}
        /> */}
      </Wrapper>
    )
  }
}

export default withApollo(PrivateQuiz)
