import styled, { css, keyframes } from 'styled-components'

export const StyleBase = styled.div`
  display: flex;
  header {
    margin: 0 0 32px;
    h1 {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;
      margin: 20px 0 40px;
    }
    p {
      line-height: 24px;
    }
  }
`

const floating = keyframes`
  from {transform: translateY(0px)}
  to {transform: translateY(3px)}
`

export const LeftColumn = styled.div`
  background-color: #6050DC;
  color: #FFFFFF;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  transition: .3s height ease;
  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    z-index: 999;
    width: 100%;
    min-height: 70px;
    /* height: 70px; */
    overflow: hidden;
    cursor: pointer;
    ${props => props.extendTips
    ? css`
      min-height: 100vh;
    `
    : css`
      height: 70px;
    `
  }
  }
  .arrow-down {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 50%;
    @media screen and (max-width: 820px) {
      ${props => !props.extendTips
    ? css`
          display: block;
        `
    : css`
          display: block;
          transform: rotate(180deg);
        `
  }
    }
    svg {
      animation: ${floating} 1s ease-in infinite alternate;
    }
  }
  svg.helper {
    position: absolute;
    right: 30px;
    top: 30px;
    /* transition: .15s all ease-in; */
    @media screen and (max-width: 820px) {
      ${props => !props.extendTips && css`
        transform: scale(.3);
        transform-origin: 55px -30px;
      `}
    }
  }
  h1 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 31px;
    margin-bottom: 40px;
    @media screen and (max-width: 820px) {
      margin-top: 70px;
      ${props => !props.extendTips && css`
        font-size: 18px;
        margin-top: 20px;
      `}
      &:before {
        content: 'Pro tips: '
      }
    }
    @media screen and (max-width: 520px) {
      ${props => !props.extendTips && css`
        font-size: 16px;
      `}
    }
  }
  ul {
    li {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      padding-left: 25px;
      position: relative;
      &:before {
        content: '>';
        position: absolute;
        left: 0;
        display: block;
      }
    }
  }
  > div {
    max-width: 560px;
    padding: 0 30px;
  }
`

export const RightColumn = styled.div`
  background-color: #1A1A1A;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  width: 50%;
  @media screen and (max-width: 820px) {
    width: 100%;
  }
  > div {
    max-width: 350px;
  }
`

export const ColumnWrapper = styled.div`
  margin: 0 auto;
`

// Form Component Style
export const FormStyle = styled.form`
  /* max-width: 460px;
  margin: 0 auto; */
  .form-control {
    margin-bottom: 15px;
    label {
      display: block;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
    }
    input {
      background-color: #FFFFFF;
      width: 100%;
      height: 40px;
      border: 0;
      border-radius: 4px;
      padding-left: 20px;
      font-size: 14px;
      font-weight: 500;
      &::placeholder {
        color: #BDBDBD;
      }
      &:focus {
        outline: 2px solid rgba(14, 99, 156, 0.6);
      }
      &::placeholder {
        color: #757575;
      }
    }
    .tooltip {
      top: -87px;
      right: -60px;
      background: #353333ed;
      position:absolute;
      padding: 10px;
      border-radius: 4px;
      max-width: 159px;
      text-align: center;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
      }
    }
    .help {
      display: block;
    }
    span {
      display: none;
      margin-top: 5px;
    }
    &.has-error {
      input {
        border: 2px solid #56201f;
        background-color: rgba(86, 32, 31, .3);
      }
      span {
        display: block;
        font-size: 13px;
      }
    }
  }
  button {
    margin-top: 25px;
    background-color: #6050DC;
    width: 100%;
    height: 40px;
    border: 0;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: .15s all;
    &:hover {
      transform: translateY(-1px)
    }
  }
`

export const Errors = styled.div`
  background-color: #56201f;
  font-size: 14px;
  padding: 10px;
  margin-bottom: 20px;
`
