import React, { useState, useRef } from "react"
import { StyleBase, Info, ScoreStyle, ShareContainer, TopBar } from "./style"
import Logo from "../../assets/logo.svg"
import ShareBlock from "./ShareBlock"

const Header = ({ score, firstTime }) => {
  if (!score && score !== 0) {
    return (
      <>
        <h1>Yooah!</h1>
        <h3>
          {firstTime
            ? "Quiz submitted successfully 🚀"
            : "nothing to commit, working tree clean"}
        </h3>
      </>
    )
  } else if (score >= 50) {
    return (
      <>
        <h1>
          Great job! You scored <ScoreStyle>{score}%</ScoreStyle>
        </h1>
        {score < 80 && (
          <Info>
            Here's some{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/hiddenfounders/web-coding-challenge/blob/master/tips-and-tricks.md"
            >
              learning resources
            </a>{" "}
            we've compiled to help you boost your knowledge of various technical
            topics (including the topic of this quiz)
          </Info>
        )}
      </>
    )
  } else {
    return (
      <>
        <h1 style={{ lineHeight: "1.8" }}>
          Not Bad, you scored <ScoreStyle>{score}%</ScoreStyle>
        </h1>
        <Info>
          Here's some{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/hiddenfounders/web-coding-challenge/blob/master/tips-and-tricks.md"
          >
            learning resources
          </a>{" "}
          we've compiled to help you boost your knowledge of various technical
          topics (including the topic of this quiz)
        </Info>
      </>
    )
  }
}

const Congratulations = ({
  score,
  submittedAt,
  quizType,
  link,
  submissionId,
  quizId,
  submissionEmail,
}) => {
  if (window.fbq) window.fbq("track", "PageView")
  const [shared, setShared] = useState(false)
  const scoreImgElement = useRef(null)
  let diffMonths = null
  let diffDays = null
  if (submittedAt) {
    const today = new Date()
    submittedAt = new Date(submittedAt)
    submittedAt.setDate(submittedAt.getDate() - 5)
    const timeDiff = Math.abs(today.getTime() - submittedAt.getTime())
    diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    const timeLeft = 90 - diffDays
    diffMonths = Number(timeLeft / 30).toFixed()
  }

  const toggleShared = async () => {
    // setShared(!shared)
  }
  const shareFacebook = (href) => {
    let url = `https://web.facebook.com/sharer/sharer.php?u=${href}`
    var win = window.open(url, "_blank")
    win.focus()
  }
  const renderMessage = () => {
    if (quizType === "private") {
      if (!diffDays || diffDays < 2) {
        return (
          <>
            <p>
              Thank you for taking this challenge. The team will be in touch
              soon to inform you of the result 🙂
            </p>
            <p>
              If your score doesn't make the cut, no worries, you can always
              take this test again in 3 months.
            </p>
            <p>
              Best of luck 🍀 <br /> - Gemography Team.
            </p>
          </>
        )
      }
      if (diffDays >= 2) {
        return (
          <>
            <p>
              {" "}
              Looks like you completed this quiz recently, if you still haven’t
              heard back from us, we encourage you to reach out to{" "}
              <a href="mailto:jobs@gemography.com" style={{ color: "#6050DC" }}>
                jobs@gemography.com
              </a>
            </p>
            <p>
              {" "}
              However you still can take this quiz again in{" "}
              {diffMonths > 1
                ? diffMonths + " months."
                : diffMonths + " month."}{" "}
            </p>
            <p>
              Best of luck 🍀 <br /> - Gemography Team.
            </p>
          </>
        )
      }
    } else {
      return (
        <div style={{ overflow: "hidden" }}>
          {quizType != "anon" && (
            <ShareBlock
              shared={shared}
              quizType={quizType}
              link={link}
              shareFacebook={shareFacebook}
              submissionId={submissionId}
              quizId={quizId}
              score={score}
              submissionEmail={submissionEmail}
            />

          )}

          <p style={{ fontSize: "14px" }}>
            Wanna suggest a question ? <br />
            If you would like to contribute additional questions to this quiz,
            you can do so{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://unitedremote.typeform.com/to/yhjT1d"
              style={{ color: "#6050DC" }}
            >
              here
            </a>
            .
          </p>
          {/*           <Info>
            <a href={link}>View in details</a>
          </Info> */}
        </div>
      )
    }
  }
  return (
    <StyleBase>
      <header>
        <TopBar>
          <img src={Logo} alt="Gemography" className="logo" />
          {quizType === "public" && (
            <div className="hiring">
              <img src={require("../../assets/icons/trumpet.svg")} />
              <a
                target="_blank"
                href="https://www.gemography.com/#featured-jobs"
              >
                We’re Hiring
              </a>
            </div>
          )}
        </TopBar>

        <Header score={score} firstTime={!diffDays || diffDays < 2} />
        {renderMessage()}
      </header>
    </StyleBase>
  )
}

export default Congratulations
