//////////////////// ALERT ////////////////
// This component isn't working on anonymous quizzes
///////////////////////////////////////////

import React from "react"
import {
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import { ShareContainer } from "./style"

export default function ShareBlock(props) {
  let {
    link,
    shared,
    shareFacebook,
    quizType,
    submissionId,
    quizId,
    score,
    submissionEmail,
  } = props

  // Heap triggers
  const reportHeapEvent = (channel) => {
    try {
      console.log("reporting to  : " + channel)
      window.heap.track("sharedScore", {
        id: submissionId,
        email: submissionEmail,
        quizId: quizId,
        channel,
        score: score,
      })
    } catch (e) {
      console.log(e)
      alert("error")
    }
  }
  return (
    <ShareContainer>
      <span className="share-title">Share your result</span> <br />
      {shared && (
        <div>
          {" "}
          >{" "}
          <a target="_blank" href={link}>
            View submission
          </a>{" "}
          <br />
        </div>
      )}
      {!shared && (
        <div>
          {quizType !== "anon"
            ? "with your friends and get details about your submission"
            : "with your friends and get some excitment rolling!"}
        </div>
      )}
      <div className="share-actions">
        <div>
          <div
            className="share-btn"
            id="share-btn"
            onClick={() => {
              shareFacebook(
                submissionId
                  ? `https://sharequiz.gemography.com/share/${submissionId}`
                  : `https://sharequiz.gemography.com/${quizId}/${score}`
              )
              reportHeapEvent("facebook")
            }}
          >
            <img src={require("../../assets/icons/fb.svg")} />
            <p>Share</p>
          </div>
        </div>
        <TwitterShareButton
          title={"Test you Git skills and see if you can top my score 🚀"}
          url={`https://sharequiz.gemography.com/share/${submissionId}?twitter=true`}
          onClick={() => {
            reportHeapEvent("twitter")
          }}
        >
          <div className="share-btn">
            <img src={require("../../assets/icons/twitter.svg")} />
            <p>Tweet</p>
          </div>
        </TwitterShareButton>

        <WhatsappShareButton
          url={`https://sharequiz.gemography.com/share/${submissionId}?whatsapp=true`}
          onClick={() => {
            reportHeapEvent("whatsapp")
          }}
        >
          <div className="share-btn">
            <img src={require("../../assets/icons/whatsapp.svg")} />
            <p>Send</p>
          </div>
        </WhatsappShareButton>

        <LinkedinShareButton
          title="Gemography | Programming Quiz"
          url={`https://sharequiz.gemography.com/share/${submissionId}?linkedin=true`}
          source="Gemography"
          onClick={() => {
            reportHeapEvent("linkedin")
          }}
        >
          <div className="share-btn">
            <img src={require("../../assets/icons/linkedin.svg")} />
            <p>Share</p>
          </div>
        </LinkedinShareButton>
      </div>
    </ShareContainer>
  )
}
