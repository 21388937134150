import React, { Component } from "react"
import { withApollo } from "react-apollo"
import { Helmet } from "react-helmet"
import qs from "qs"

import { GET_SUBMISSION } from "../actions"
import {
  StyleBase,
  LeftColumn,
  ColumnWrapper,
  RightColumn,
  FormStyle,
  Errors,
} from "./style"
import Checkbox from "../Components/Checkbox"

const LOGOS = {
  React: "https://svgur.com/i/K7i.svg",
  Git: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
}

class Welcome extends Component {
  constructor() {
    super()
    const query = window.location.search.split("?")[1]
    const { qid } = qs.parse(query)

    this.state = {
      email: "",
      name: "",
      qid,
      term: false,
      errors: {
        qid: null,
        email: null,
        term: null,
      },
      extendTips: false,
      hover: false,
    }
  }
  handleInputChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name
    this.setState({
      [name]:
        target.type === "text" && target.name === "email"
          ? value.trim()
          : value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    })
  }

  toggleTipsUI = () => {
    if (window.innerWidth > 820) {
      return
    }
    this.setState({ extendTips: !this.state.extendTips })
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover })
  }

  isValid() {
    // eslint-disable-next-line
    const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return {
      email: emailRE.test(this.state.email),
      term: !!this.state.term,
    }
  }

  handleFormSubmit = async (e) => {
    e.preventDefault()
    if (this.props.quizType === "anon") {
      this.props.handleStartQuiz()
    } else {
      const { email, qid: quiz, name } = this.state
      const validation = this.isValid()
      if (!validation.email || !validation.term) {
        window.heap.addUserProperties({
          Email: email,
          Name: name,
          Quiz: quiz,
          "Login form error": true,
        })
        this.setState({
          errors: {
            ...this.state.errors,
            email: !validation.email,
            term: !validation.term,
          },
        })
      } else {
        try {
          const variables = { submission: { email, name, quiz } }
          const {
            data: { submission },
          } = await this.props.client.query({
            variables: {
              ...variables,
              email: email.trim(),
            },
            query: GET_SUBMISSION,
          })
          window.heap.addUserProperties({
            Email: email,
            Name: name,
            Quiz: quiz,
          })
          this.props.handleStartQuiz({ submission }, this.state.email)
        } catch (error) {
          window.heap.addUserProperties({
            Email: email,
            Name: name,
            Quiz: quiz,
            "Login error": true,
            error,
          })
          this.setState({
            errors: {
              ...this.state.errors,
              qid:
                "Couldn't find an email, please enter the same you used for your application.",
            },
          })
        }
      }
    }
  }
  renderTitle = (quizType, quizName) => {
    if (quizType === "public")
      return (
        <div style={{ display: "flex", alignItems: "left" }}>
          {" "}
          <img
            src={
              LOGOS.hasOwnProperty([quizName])
                ? LOGOS[quizName]
                : "https://svgur.com/i/K7i.svg"
            }
            style={{ width: "60px", height: "60px", marginBottom: "18px" }}
          />{" "}
          <div
            style={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "25px",
              lineHeight: "47px",
              color: "#F8F8FF",
              margin: "5px 12px",
            }}
          >
            {quizName}
          </div>{" "}
        </div>
      )
    else {
      return (
        <>
          <img src={require("../assets/logo.svg")} style={{ width: "140px" }} />
          <h1>{quizName}</h1>
        </>
      )
    }
  }

  render() {
    const { errors, term, email, name, extendTips } = this.state
    const { quizType, quizName, questionsNbr } = this.props
    const display = this.state.hover ? "block" : "none"
    return (
      <StyleBase>
        <Helmet>
          <title>{`${quizName} Quiz | Gemography`}</title>
        </Helmet>
        <LeftColumn onClick={this.toggleTipsUI} extendTips={extendTips}>
          {quizType == "public" && (
            <ColumnWrapper>
              <svg
                className="helper"
                width="221"
                height="282"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd" opacity=".102">
                  <path
                    d="M110.159 61.686a3.65 3.65 0 0 0-4.985 1.345c-2.218 3.842-7.137 5.169-10.965 2.959-3.828-2.21-5.138-7.134-2.92-10.976 2.218-3.842 7.137-5.169 10.965-2.959a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989c-7.309-4.22-16.7-1.685-20.933 5.649-4.234 7.334-1.733 16.733 5.575 20.953 7.308 4.219 16.699 1.685 20.933-5.649a3.65 3.65 0 0 0-1.327-4.988zM88.706 98.843a3.65 3.65 0 0 0-4.984 1.345c-2.218 3.842-7.137 5.17-10.966 2.959-3.828-2.21-5.138-7.134-2.92-10.976 2.218-3.841 7.137-5.169 10.965-2.958a3.65 3.65 0 0 0 4.985-1.345 3.65 3.65 0 0 0-1.328-4.99c-7.308-4.219-16.699-1.685-20.933 5.649-4.234 7.334-1.733 16.734 5.575 20.953 7.308 4.22 16.7 1.685 20.933-5.648a3.65 3.65 0 0 0-1.327-4.989z"
                    fill="#D5DADE"
                  />
                  <path
                    d="M121.833 126.977L98.27 113.373a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989zm7.801-13.512L106.07 99.861a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989zm-35.986 46.783l-16.831-9.718a3.65 3.65 0 0 0-4.984 1.345 3.65 3.65 0 0 0 1.327 4.99l16.83 9.716a3.65 3.65 0 0 0 4.985-1.344 3.65 3.65 0 0 0-1.327-4.99zm14.532-9.625l-23.562-13.604a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.99zm65.401-43.313l-53.858-31.095a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.99l53.858 31.094a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.988zm-5.664-21.285l-40.393-23.322a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.99l40.394 23.32a3.65 3.65 0 0 0 4.984-1.344 3.65 3.65 0 0 0-1.328-4.99zM67.253 136a3.65 3.65 0 0 0-4.984 1.346c-2.218 3.841-7.137 5.169-10.966 2.958-3.828-2.21-5.138-7.133-2.92-10.975 2.218-3.842 7.137-5.169 10.965-2.959a3.65 3.65 0 0 0 4.985-1.345 3.65 3.65 0 0 0-1.328-4.989c-7.308-4.22-16.699-1.685-20.933 5.649-4.234 7.333-1.733 16.733 5.575 20.953 7.308 4.219 16.7 1.685 20.933-5.649a3.65 3.65 0 0 0-1.327-4.988z"
                    fill="#FFF"
                  />
                  <path
                    d="M214.349 67.793L99.9 1.716C94.448-1.432 87.442.458 84.283 5.93L2.373 147.804c-3.16 5.471-1.294 12.483 4.159 15.631l57.224 33.039a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989l-57.225-33.039c-1.972-1.138-2.647-3.675-1.504-5.654L90.594 9.574a4.143 4.143 0 0 1 5.65-1.525l114.448 66.077c1.973 1.14 2.647 3.676 1.505 5.655l-60.459 104.717a3.65 3.65 0 0 0 1.328 4.989 3.65 3.65 0 0 0 4.984-1.345l60.46-104.717c3.158-5.472 1.292-12.484-4.16-15.632z"
                    fill="#FFF"
                  />
                  <path
                    d="M170 213.417a13.41 13.41 0 0 0-9.518-2.417c-.051-4.18-1.928-8.207-5.363-10.679a13.413 13.413 0 0 0-9.518-2.416c-.051-4.18-1.928-8.207-5.363-10.679a13.422 13.422 0 0 0-7.142-2.507l23.285-40.329a13.454 13.454 0 0 0 1.162-10.811 13.392 13.392 0 0 0-7.246-8.104c-6.164-2.778-13.779-.127-17.337 6.036l-23.343 40.431-14.651 3.953c-7.786 2.101-13.828 7.971-16.163 15.704l-10.3 34.102c-4.173 13.82 1.615 28.494 14.074 35.688l28.464 16.433c14.733 8.506 33.663 3.399 42.2-11.386l20.199-34.987c3.559-6.163 2.047-14.084-3.44-18.032zm-23.07 49.374c-6.52 11.292-20.979 15.193-32.231 8.697l-28.464-16.434c-9.516-5.494-13.937-16.703-10.75-27.257l10.3-34.103c1.597-5.29 5.732-9.307 11.058-10.744l7.603-2.052-6.312 10.934a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.985-1.345l34.826-60.322c1.703-2.948 5.223-4.273 8.016-3.014a6.022 6.022 0 0 1 3.31 3.691 6.119 6.119 0 0 1-.529 4.916l-32.179 55.736a3.65 3.65 0 0 0 1.328 4.989 3.65 3.65 0 0 0 4.984-1.345l2.925-5.067a6.117 6.117 0 0 1 3.993-2.915 6.022 6.022 0 0 1 4.852 1.02c2.486 1.79 3.1 5.501 1.397 8.45l-4.597 7.963a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.984-1.345l2.926-5.067a6.117 6.117 0 0 1 3.993-2.916 6.024 6.024 0 0 1 4.851 1.021c2.486 1.79 3.1 5.5 1.397 8.45l-4.597 7.963a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.984-1.345l2.926-5.067a6.117 6.117 0 0 1 3.993-2.916 6.02 6.02 0 0 1 4.851 1.022c2.486 1.788 3.1 5.5 1.397 8.449l-20.2 34.987h-.002z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
              <h1>Before you get started :</h1>
              <div className="arrow-down">
                <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.657 1L5 4.657 1.343 1"
                    stroke="#FFFFFF"
                    strokeWidth="1.5"
                    fill="none"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <ul>
                <li>
                  Once you start the quiz, you won't be able to pause, so make
                  sure you have 10-15 minutes before you start.{" "}
                </li>
                <li>
                  Each question has a time limit ⏱️ and can only be answered
                  once.{" "}
                </li>
                <li>
                  You don't need to know all the answers. You can always skip .
                </li>
                <li>On average, you can expect between 5 to 15 questions. </li>
                <li>
                  All questions are multi-choice, so there is nothing to type.
                </li>
              </ul>
            </ColumnWrapper>
          )}
          {quizType != "public" && (
            <ColumnWrapper>
              <svg
                className="helper"
                width="221"
                height="282"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd" opacity=".102">
                  <path
                    d="M110.159 61.686a3.65 3.65 0 0 0-4.985 1.345c-2.218 3.842-7.137 5.169-10.965 2.959-3.828-2.21-5.138-7.134-2.92-10.976 2.218-3.842 7.137-5.169 10.965-2.959a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989c-7.309-4.22-16.7-1.685-20.933 5.649-4.234 7.334-1.733 16.733 5.575 20.953 7.308 4.219 16.699 1.685 20.933-5.649a3.65 3.65 0 0 0-1.327-4.988zM88.706 98.843a3.65 3.65 0 0 0-4.984 1.345c-2.218 3.842-7.137 5.17-10.966 2.959-3.828-2.21-5.138-7.134-2.92-10.976 2.218-3.841 7.137-5.169 10.965-2.958a3.65 3.65 0 0 0 4.985-1.345 3.65 3.65 0 0 0-1.328-4.99c-7.308-4.219-16.699-1.685-20.933 5.649-4.234 7.334-1.733 16.734 5.575 20.953 7.308 4.22 16.7 1.685 20.933-5.648a3.65 3.65 0 0 0-1.327-4.989z"
                    fill="#D5DADE"
                  />
                  <path
                    d="M121.833 126.977L98.27 113.373a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989zm7.801-13.512L106.07 99.861a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989zm-35.986 46.783l-16.831-9.718a3.65 3.65 0 0 0-4.984 1.345 3.65 3.65 0 0 0 1.327 4.99l16.83 9.716a3.65 3.65 0 0 0 4.985-1.344 3.65 3.65 0 0 0-1.327-4.99zm14.532-9.625l-23.562-13.604a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.989l23.563 13.604a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.99zm65.401-43.313l-53.858-31.095a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.99l53.858 31.094a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.988zm-5.664-21.285l-40.393-23.322a3.65 3.65 0 0 0-4.985 1.345 3.65 3.65 0 0 0 1.328 4.99l40.394 23.32a3.65 3.65 0 0 0 4.984-1.344 3.65 3.65 0 0 0-1.328-4.99zM67.253 136a3.65 3.65 0 0 0-4.984 1.346c-2.218 3.841-7.137 5.169-10.966 2.958-3.828-2.21-5.138-7.133-2.92-10.975 2.218-3.842 7.137-5.169 10.965-2.959a3.65 3.65 0 0 0 4.985-1.345 3.65 3.65 0 0 0-1.328-4.989c-7.308-4.22-16.699-1.685-20.933 5.649-4.234 7.333-1.733 16.733 5.575 20.953 7.308 4.219 16.7 1.685 20.933-5.649a3.65 3.65 0 0 0-1.327-4.988z"
                    fill="#FFF"
                  />
                  <path
                    d="M214.349 67.793L99.9 1.716C94.448-1.432 87.442.458 84.283 5.93L2.373 147.804c-3.16 5.471-1.294 12.483 4.159 15.631l57.224 33.039a3.65 3.65 0 0 0 4.984-1.345 3.65 3.65 0 0 0-1.327-4.989l-57.225-33.039c-1.972-1.138-2.647-3.675-1.504-5.654L90.594 9.574a4.143 4.143 0 0 1 5.65-1.525l114.448 66.077c1.973 1.14 2.647 3.676 1.505 5.655l-60.459 104.717a3.65 3.65 0 0 0 1.328 4.989 3.65 3.65 0 0 0 4.984-1.345l60.46-104.717c3.158-5.472 1.292-12.484-4.16-15.632z"
                    fill="#FFF"
                  />
                  <path
                    d="M170 213.417a13.41 13.41 0 0 0-9.518-2.417c-.051-4.18-1.928-8.207-5.363-10.679a13.413 13.413 0 0 0-9.518-2.416c-.051-4.18-1.928-8.207-5.363-10.679a13.422 13.422 0 0 0-7.142-2.507l23.285-40.329a13.454 13.454 0 0 0 1.162-10.811 13.392 13.392 0 0 0-7.246-8.104c-6.164-2.778-13.779-.127-17.337 6.036l-23.343 40.431-14.651 3.953c-7.786 2.101-13.828 7.971-16.163 15.704l-10.3 34.102c-4.173 13.82 1.615 28.494 14.074 35.688l28.464 16.433c14.733 8.506 33.663 3.399 42.2-11.386l20.199-34.987c3.559-6.163 2.047-14.084-3.44-18.032zm-23.07 49.374c-6.52 11.292-20.979 15.193-32.231 8.697l-28.464-16.434c-9.516-5.494-13.937-16.703-10.75-27.257l10.3-34.103c1.597-5.29 5.732-9.307 11.058-10.744l7.603-2.052-6.312 10.934a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.985-1.345l34.826-60.322c1.703-2.948 5.223-4.273 8.016-3.014a6.022 6.022 0 0 1 3.31 3.691 6.119 6.119 0 0 1-.529 4.916l-32.179 55.736a3.65 3.65 0 0 0 1.328 4.989 3.65 3.65 0 0 0 4.984-1.345l2.925-5.067a6.117 6.117 0 0 1 3.993-2.915 6.022 6.022 0 0 1 4.852 1.02c2.486 1.79 3.1 5.501 1.397 8.45l-4.597 7.963a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.984-1.345l2.926-5.067a6.117 6.117 0 0 1 3.993-2.916 6.024 6.024 0 0 1 4.851 1.021c2.486 1.79 3.1 5.5 1.397 8.45l-4.597 7.963a3.65 3.65 0 0 0 1.327 4.989 3.65 3.65 0 0 0 4.984-1.345l2.926-5.067a6.117 6.117 0 0 1 3.993-2.916 6.02 6.02 0 0 1 4.851 1.022c2.486 1.788 3.1 5.5 1.397 8.449l-20.2 34.987h-.002z"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                </g>
              </svg>
              <h1>Few things to keep in mind before you start:</h1>
              <div className="arrow-down">
                <svg width="10" height="6" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.657 1L5 4.657 1.343 1"
                    stroke="#FFFFFF"
                    strokeWidth="1.5"
                    fill="none"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <ul>
                <li>
                  Each question in the quiz has one timer and can only be
                  answered once.{" "}
                </li>
                <li>
                  You don't have to know the answers to all questions. You can
                  always skip.{" "}
                </li>
                <li>
                  {quizType == "public" && questionsNbr
                    ? `This quiz consists of ${questionsNbr} multiple choice questions.`
                    : "On average, a quiz has between 10 to 20 multi-choice questions."}
                </li>
                <li>
                  Once you start the quiz, you can't stop, so please make sure
                  you have between 15 to 25 minutes before you start.{" "}
                </li>
              </ul>
            </ColumnWrapper>
          )}
        </LeftColumn>
        <RightColumn>
          <ColumnWrapper>
            <header>
              {this.renderTitle(quizType, quizName)}
              {quizType === "private" ? (
                <p>
                  Hey there, to start the quiz please enter the same email you
                  used to apply for this position. may the force be with you!
                </p>
              ) : (
                  <p>
                    Hey there, get comfortable this is going to be easy. may the
                    force be with you!
                </p>
                )}
              <br />
              <p>
                If you face any trouble while taking the quiz please reach out
                to us via the{" "}
                <span style={{ color: "#00a3ff" }}>Facebook Messenger</span>{" "}
                bubble on the bottom of the screen.
              </p>
            </header>
            <FormStyle onSubmit={this.handleFormSubmit}>
              {errors.qid && (
                <Errors>
                  <span>{errors.qid}</span>
                </Errors>
              )}
              {quizType === "private" && (
                <>
                  <div
                    className={
                      errors.email ? "form-control has-error" : "form-control"
                    }
                  >
                    <label htmlFor="email">Email</label>
                    <input
                      onChange={this.handleInputChange}
                      value={email}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Your email address"
                    />
                    <span>* Please enter a valid email</span>
                  </div>
                  <Checkbox
                    onChange={this.handleInputChange}
                    value={term}
                    name="term"
                    label="I won't cheat, I promise (Developer's word 🖖)."
                  />
                </>
              )}
              {quizType === "public" && (
                <>
                  <div
                    className={
                      errors.name ? "form-control has-error" : "form-control"
                    }
                  >
                    <label htmlFor="name">Username</label>
                    <input
                      onChange={this.handleInputChange}
                      value={name}
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your username"
                      required
                    />
                    <span>* Please enter a valid name</span>
                  </div>
                  <div
                    className={
                      errors.email ? "form-control has-error" : "form-control"
                    }
                  >
                    <label htmlFor="email">Email</label>
                    <div style={{ position: "relative" }}>
                      <img
                        className="help"
                        onMouseOver={this.toggleHover}
                        onMouseOut={this.toggleHover}
                        src={require("../assets/icons/help.svg")}
                        style={{
                          width: "20px",
                          position: "absolute",
                          right: "10px",
                          top: "10px",
                        }}
                      />
                      <input
                        onChange={this.handleInputChange}
                        value={email}
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Your email address"
                      />
                      <p className="tooltip" style={{ display }}>
                        We need your email to send you feedback about your
                        submission
                      </p>
                    </div>
                    <span>* Please enter a valid email</span>
                  </div>
                  <Checkbox
                    onChange={this.handleInputChange}
                    value={term}
                    name="term"
                    label="I won't cheat, I promise (Developer's word 🖖)."
                  />
                </>
              )}
              {errors.term && (
                <Errors>
                  <span>
                    Please check the box below to confirm you'll answer the
                    questions without any help.
                  </span>
                </Errors>
              )}
              <button type="submit" id="startQuiz" className="startQuiz">
                Start Quiz
              </button>
            </FormStyle>
            {quizType === "public" && (
              <img
                src={require("../assets/gemo.svg")}
                style={{ width: "168px", marginTop: "33px" }}
              />
            )}
          </ColumnWrapper>
        </RightColumn>
      </StyleBase>
    )
  }
}

export default withApollo(Welcome)
